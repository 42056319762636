import {
  Grid,
  Flex,
  Heading,
  UnorderedList,
  Text,
  Image,
  VStack,
  ListItem,
  useColorModeValue,
  useDisclosure,
  SlideFade,
  useMediaQuery,
} from "@chakra-ui/react";
import { useLayoutEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import { iconBuilding } from "../../styles/MediaPack";
import { styles } from "../../styles/styles";
import { CardIntroductionProps } from "./CardInformation";

const CardStrategies = ({
  colorMode,
  isSmallerThan1000,
  isSmallerThan1550,
}: CardIntroductionProps) => {
  const color = useColorModeValue(styles.colors.darkRed, styles.colors.red);
  const { isOpen, onToggle } = useDisclosure();
  const { ref, inView } = useInView({
    threshold: 0.4,
  });

  const [isSmallerThan700] = useMediaQuery("(max-width: 700px)");

  const grid = useMemo(() => {
    if (isSmallerThan1550) return "100%";
    return "1fr 30%";
  }, [isSmallerThan1550]);

  useLayoutEffect(() => {
    if (!isOpen && inView) {
      onToggle();
    }
  }, [inView, isOpen, onToggle]);
  return (
    <SlideFade in={isOpen} offsetY="-100px" delay={0.25}>
      <Grid
        ref={ref}
        templateColumns={grid}
        bg={
          colorMode === "dark"
            ? styles.bg.lightTransparent
            : styles.bg.darkTransparent
        }
        rounded="3xl"
        py={4}
        gap={2}
        alignItems="start"
        style={{boxShadow:"0px 0px 5px 3px rgba(65,136,255,0.75)",WebkitBoxShadow:"0px 0px 5px 3px rgba(65,136,255,0.75)",MozBoxShadow: "0px 0px 5px 3px rgba(65,136,255,0.75)", marginTop:"50px"}}
      >
        <Flex direction="column" px={isSmallerThan700 ? 4 : 24} gap={4}>
          <Heading style={{color:"#00BFFF"}}>Benefits Associated with Axxeleum NaaS</Heading>
          <UnorderedList spacing={4} textAlign="start" style={{color:"#fff"}}>
            <ListItem>
              <Text style={{color:"#fff",lineHeight:"28px",fontSize:"16px"}}>
              <span style={{color:"#00BFFF", fontWeight:"bold"}}>Sustainable Model: </span>Most DaaS initiatives have difficulties with sustainability. Fortunately,
our tokenomics and operating model have addressed this, which have
been subjected to significant quantitative study and testing to verify
their viability
              </Text>
            </ListItem>
            <ListItem>
              <Text style={{color:"#fff",lineHeight:"28px",fontSize:"16px"}}>
              <span style={{color:"#00BFFF", fontWeight:"bold"}}>Our Model for Long-Term Success: </span>
              Axxeleum redefines the DaaS industry by issuing lower incentive
rates than the industry standard. Investors have suffered losses due
to investments in unsustainable protocols, whose prices ultimately
plummet to zero. Due to their extensive dependence on “ponzinomics,”
the only members who benefit are those who get in early in the game.
Axxeleum understands that savvy investors are not concerned with the
number of tokens that they receive as a daily reward, but rather with
the sustainability model of the protocol.
              </Text>
            </ListItem>

            <ListItem>
              <Text style={{color:"#fff",lineHeight:"28px",fontSize:"16px"}}>
              <span style={{color:"#00BFFF", fontWeight:"bold"}}>Our Native Token Utility: </span>
By including usefulness into our native token, we will establish longterm buying pressure and, consequently, good price movement. To
accomplish this, we are building a broader ecosystem around $AXL.
Axxeleum is committed to ensuring that member profit is prioritized
and that the price of $AXL continues to rise in a consistent upward
trend.
              </Text>
            </ListItem>
         
          </UnorderedList>
        </Flex>
        {isSmallerThan1550 ? (
          <></>
        ) : (
          <VStack alignSelf={"center"}>
            <Image src={iconBuilding} objectFit="contain" boxSize="50%" />
          </VStack>
        )}
      </Grid>
    </SlideFade>
  );
};

export default CardStrategies;
