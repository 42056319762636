import { bgDark, bgLight } from "./MediaPack";

export const styles = {
  bg: {
    dark: bgDark,
    light: bgLight,
    darkTransparent: "rgba(0, 12, 56,0.9)",
    lightTransparent: "rgba(12, 12, 12,0.9)",
  },
  colors: {
    metis: "#00DACC",
    white: "#fff5e6",
    lightRed: "#c1464f",
    red: "#c6271a",
    darkRed: "#7c0e14",
    black: "#0c0c0c",
    yellow: "#facd1e",
    blue: "#2b8599",
    ethereum: "#3c3c3d",
    ftm: "#000C38",
    avax: "#E84142",
    darkBlue: "#000C38",
    lightBlue: "#4188FF"
  },
};
