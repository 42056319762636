import {
  Grid,
  Flex,
  HStack,
  Button,
  Text,
  useDisclosure,
  SlideFade,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { useLayoutEffect, useMemo } from "react";
import { GiBrokenTablet, GiWaxTablet } from "react-icons/gi";
import { useInView } from "react-intersection-observer";
import { styles } from "../../styles/styles";
import { CardIntroductionProps } from "./CardInformation";

const CardIntroduction = ({
  colorMode,
  isSmallerThan1550,
  isSmallerThan1000,
}: CardIntroductionProps) => {
  const { isOpen, onToggle } = useDisclosure();
  const { ref, inView } = useInView();

  const grid = useMemo(() => {
    if (isSmallerThan1550) {
      if (isSmallerThan1000) return "100% 1fr";
      return "70% 1fr";
    }
    return "40% 1fr";
  }, [isSmallerThan1000, isSmallerThan1550]);

  const [isSmallerThan700] = useMediaQuery("(max-width: 700px)");

  useLayoutEffect(() => {
    if (!isOpen && inView) {
      onToggle();
    }
  }, [inView, isOpen, onToggle]);
  return (
    <SlideFade in={isOpen} offsetX="50px" delay={0.75}>
      <Grid templateColumns={grid} ref={ref}>
        <Flex
          alignItems="flex-start"
          flexFlow="column"
          textAlign="left"
          gap={4}
          justifyContent="center"
        >
          <Text fontWeight={700} fontSize="2rem" color="#fff" marginTop="100px" style={{textShadow:"1px 1px 8px rgba(65,136,255)"}}>
            Axxeleum Offers Long Term Revenue Streams
            <br />
            For It's Investors
          </Text>
          <Text fontWeight={300} color="#fff" fontSize="1.5rem" >
          We also assist investors in maximizing their earnings during bull markets while
effectively mitigating the consequences of downturn periods
          </Text>
          {isSmallerThan700 ? (
            <VStack
              w="100%"
              justifyContent={isSmallerThan1000 ? "center" : "start"}
            >
              <Button
                style={{background:"#000C38"}}
                leftIcon={<GiWaxTablet fontSize="1.5rem" />}
                variant="solid"
                w="100%"
              >
               Whitepaper
              </Button>
              <Button
                leftIcon={<GiBrokenTablet fontSize="1.5rem" />}
                variant="solid2"
                w="100%"
              >
                Create Node
              </Button>
            </VStack>
          ) : (
            <HStack
              w="100%"
              justifyContent={isSmallerThan1000 ? "center" : "start"}
            >
              <Button
                style={{background:"#000C38"}}
                leftIcon={<GiWaxTablet fontSize="1.5rem" />}
                variant="solid"
                w="100%"
              >
                Whitepaper
              </Button>
              <Button
              
                leftIcon={<GiBrokenTablet fontSize="1.5rem" />}
                variant="solid2"
                w="100%"
              >
                Create Node
              </Button>
            </HStack>
          )}
        </Flex>
        {/* <Flex alignItems="center" justifyContent="end">
          <Image
            src={
              colorMode === "dark"
                ? logoCoinRedTransparent
                : logoCoinBlackTransparent
            }
            objectFit="contain"
          />
        </Flex> */}
      </Grid>
    </SlideFade>
  );
};

export default CardIntroduction;
