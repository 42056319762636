import { Footer } from "./Components/Footer/Footer";
import { Box, Grid, useColorModeValue } from "@chakra-ui/react";
import "@fontsource/philosopher";
//import "@fontsource/alegreya-sans-sc";
import Header from "./Components/Header/Header";
import Content from "./Components/Content/Content";
import { styles } from "./styles/styles";
import { Route, Routes } from "react-router-dom";
import FAQ from "./Components/FAQ/FAQ";
import Fonts from "./Hurme";

export const App = () => {
  const bgImage = useColorModeValue(styles.bg.light, styles.bg.dark);
  const bgColor = useColorModeValue(
    "rgba(12, 12, 12,0.5)",
    "rgba(255, 245, 230,0.5)"
  );
  return (
    <Box textAlign="center" fontSize="xl">
      {/* Main layout */}
      <Grid
        minH="100vh"
        bgImage={bgImage}
        backgroundRepeat="no-repeat"
        backgroundAttachment="fixed"
        templateRows="10vh 1fr auto"
        templateColumns="1fr 90% 1fr"
        style={{backgroundSize: "cover",backgroundPosition: "center center"}}
        gridTemplateAreas={{
          base: `'pl header pr' 'pl body pr' 'footer footer footer'`,
          sm: `'pl header pr' 'pl body pr' 'footer footer footer'`,
        }}
      >
        {/* Padding Left 
        <Box gridArea="pl" />
        {/* Padding Right 
        <Box gridArea="pr" />*/}
        {/* Header Container */}
        <Header bgColor={bgColor} />
        {/* Body Container */}
        <Routes>
          <Route path="/" element={<Content />} />
          <Route path="/faq" element={<FAQ />} />
        </Routes>
        {/* Footer Container */}
        <Footer />
      </Grid>
    </Box>
  );
};
