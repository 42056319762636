import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import {
  Collapse,
  ColorMode,
  Flex,
  HStack,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { styles } from "../../styles/styles";

const FAQ = () => {
  const { colorMode } = useColorMode();
  return (
    <Flex
      flexFlow="column"
      gap={6}
      gridArea="body"
      justifyContent="center"
      py={12}
      px={4}
    >
      <FAQCard question="What is Aerarium Fi?" colorMode={colorMode}>
        <Text textAlign="justify">
          Aerarium Fi is a next-generation Treasury-as-a-Service protocol built
          to work for you. Aerarium is the Latin word for ‘treasury’. We chose
          this name to emphasize the role of the treasury in our design. We have
          taken the strengths of various ‘node’ protocols, tweaked the
          tokenomics and distribution for sustainability, and created value
          beyond creating Fractals. We are doing the hard work of building
          lasting relationships to bring value to our holders, and profitability
          to stakers and farmers. To assist in protocol growth, we will sell
          tokens from treasury when market cap value is +20% vs the treasury
          value, as well as buy tokens when it is -20% below treasury value.
          This will allow us to use the funds generated to keep prices
          increasing slowly and steadily. Our passive income model and
          compounding map has been designed to significantly reduce sell
          pressure after our distribution phase and provide income from various
          other sources to be directed to our Fractal holders and farmers in the
          form of basket rewards (up to 3 tokens) for easy compounding.
        </Text>
      </FAQCard>
      <FAQCard
        question="What is a Fractal and how do I make one?"
        colorMode={colorMode}
      >
        <Text textAlign="justify">
          You can create a Fractal in our Dapp to begin earning passive rewards.
        </Text>
      </FAQCard>
      <FAQCard
        question="FPO - Fractional Protocol Ownership"
        colorMode={colorMode}
      >
        <Text textAlign="justify">
          Your total value of Fractals determines your FPO (Fractional Protocol
          Ownership). You, the investor, are purchasing a part of our protocol,
          and as such will share dividends from the protocol as well.
        </Text>
      </FAQCard>
      <FAQCard
        question="Is there a maximum amount of Fractals per wallet?"
        colorMode={colorMode}
      >
        <Text>25 Fractals is the current maximum per wallet.</Text>
      </FAQCard>
      <FAQCard question="How many Fractals are there?" colorMode={colorMode}>
        <Text textAlign="justify">
          There are currently ~2,300 Fractals with a soft-cap of 2,500.
        </Text>
      </FAQCard>
      <FAQCard
        question="What is APR on Fractals? Will this change?"
        colorMode={colorMode}
      >
        <Text textAlign="justify">
          The current APR% on a Fractal is 365% in $AERA tokens. This amount
          will be growing as we begin to distribute HERMES and other Ecosystem
          tokens as rewards to Fractal owners.
        </Text>
      </FAQCard>
      <FAQCard
        question="What happens if I don’t pay the monthly fee for Fractal?"
        colorMode={colorMode}
      >
        <Text textAlign="justify">
          Your Fractal will become inactive and stop earning rewards. This
          process will be automated in the future and users can simply choose to
          pay months in advance, or to enable an ‘auto-pay’ feature.
        </Text>
      </FAQCard>
      <FAQCard
        question="Can I transfer Fractals to other wallets?"
        colorMode={colorMode}
      >
        <Text textAlign="justify">
          Currently, no. However, they will be after we migrate our Fractals to
          ERC-721 as outlined in the documents.
        </Text>
      </FAQCard>
      <FAQCard question="Is there any sales tax?" colorMode={colorMode}>
        <Text>There is no buy or sell tax on the $AERA token.</Text>
      </FAQCard>
      <FAQCard question="Is there any claim tax?" colorMode={colorMode}>
        <Text textAlign="justify">
          There is currently a 15% claim tax that will be scaled down to 0% over
          time.
        </Text>
      </FAQCard>
      <FAQCard
        question="Where can I stake my Liquidity Tokens (LP)?"
        colorMode={colorMode}
      >
        <Text textAlign="justify">
          You can currently stake your AERA-m.USDC Hermes vAMM LP at Starstream,
          and your AERA-METIS LP at Netswap!
        </Text>
      </FAQCard>
      <FAQCard
        question="Where is the single stake vault?"
        colorMode={colorMode}
      >
        <Text textAlign="justify">
          The single stake vault only appears when the Fractal soft-cap has been
          met.
        </Text>
      </FAQCard>
      <FAQCard
        question="How else can I profit from the Aerarium ecosystem?"
        colorMode={colorMode}
      >
        <Text textAlign="justify">
          We have extensive documentation outlining the various profit paths for
          you to follow. Each is designed to help reduce sell pressure and
          encourage participation in the ecosystem for maximum benefit to all
          protocols and a sustainable growth model to allow profit for many.
          Current alternative options: -(When Fractals are capped) Single stake
          $AERA vault for 100-120% APY -Farm AERA-m.USDC pair at Starstream
          -Farm AERA-METIS pair at Netswap
        </Text>
      </FAQCard>
      <FAQCard question="Why Metis chain?" colorMode={colorMode}>
        <Text textAlign="justify">
          Metis is the L2 leader in Defi, and has amazing transaction speeds,
          combined with low gas costs which made it quite appealing to us. We
          also saw opportunity in getting involved early with builder fees, and
          the Hermes veNFT to assist us in creating a truly dynamic protocol
          with huge upside potential.
        </Text>
      </FAQCard>
    </Flex>
  );
};
interface FAQCardInterface {
  question: string;
  children: React.ReactNode;
  colorMode: ColorMode;
}
const FAQCard = ({ question, children, colorMode }: FAQCardInterface) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <VStack
      w="100%"
      bg={
        colorMode === "dark"
          ? styles.bg.lightTransparent
          : styles.bg.darkTransparent
      }
      alignItems="start"
      p={6}
      rounded="md"
    >
      <HStack w="100%" justifyContent="space-between">
        <Text
          fontWeight={700}
          color={
            colorMode === "dark" ? styles.colors.red : styles.colors.darkRed
          }
        >
          {question}
        </Text>
        {show ? (
          <ArrowUpIcon onClick={() => setShow(!show)} cursor="pointer" />
        ) : (
          <ArrowDownIcon onClick={() => setShow(!show)} cursor="pointer" />
        )}
      </HStack>
      <Collapse startingHeight={0} in={show}>
        {children}
      </Collapse>
    </VStack>
  );
};
export default FAQ;
