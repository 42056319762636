import {
  VStack,
  HStack,
  Divider,
  Text,
  Image,
  Link,
  useMediaQuery,
} from "@chakra-ui/react";
import { BiLinkExternal } from "react-icons/bi";
import {
  FaTwitter,
  FaDiscord,
  FaBook,
  FaCoins,
  FaTelegram,
} from "react-icons/fa";
import { tulipDAO } from "../../styles/MediaPack";
import { styles } from "../../styles/styles";
import { aeraLinks } from "../Header/Header";
export function Footer() {
  const [isSmallerThan700] = useMediaQuery("(max-width: 700px)");

  return (
    <VStack
      gridArea="footer"
      minW="100%"
      p={4}
      gap={4}
    >
      <HStack w="max-content" gap={4} minW="100%" justifyContent="center">
        {isSmallerThan700 ? (
          <VStack>
            <VStack alignItems={"start"} minH="100%">
              <Text color="white">
                <b>Social Media</b>
              </Text>
              <HStack>
                <FaTwitter color="white" />
                <Link
                  href=""
                  color="white"
                  target="_blank"
                  rel="noreferrer"
                >
                  @Axxeleum
                </Link>
              </HStack>
              <HStack>
                <FaDiscord color="white" />
                <Link
                  href=""
                  color="white"
                  target="_blank"
                  rel="noreferrer"
                >
                  @Axxeleum Finance
                </Link>
              </HStack>
            </VStack>

      

       
          </VStack>
        ) : (
          <>
            <VStack alignItems={"start"} minH="100%">
              <Text color="white">
                <b>Social Media</b>
              </Text>
              <HStack>
                <FaTwitter color="white" />
                <Link
                  href=""
                  color="white"
                  target="_blank"
                  rel="noreferrer"
                >
                  @Axxeleum
                </Link>
              </HStack>
              <HStack>
                <FaDiscord color="white" />
                <Link
                  href=""
                  color="white"
                  target="_blank"
                  rel="noreferrer"
                >
                  Axxeleum Finance
                </Link>
              </HStack>
            </VStack>

           
          </>
        )}
      </HStack>
      <Text color="white">©2022 All Rights Reserved. Axxeleum</Text>
    </VStack>
  );
}
