import {
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Image,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { FaDiscord, FaHome, FaTelegram, FaTwitter } from "react-icons/fa";
import { GiCrownCoin, GiSpartanHelmet, GiWaxTablet } from "react-icons/gi";
import { HamburgerIcon } from "@chakra-ui/icons";
import {
  logoCoinBlackTransparent,
  logoCoinWhiteTransparent,
} from "../../styles/MediaPack";
import { useNavigate } from "react-router-dom";

interface HeaderProps {
  bgColor: string;
}

export const aeraLinks = {
  discord: "http://discord.gg/aerariumfi",
  telegram: "https://t.me/aerariumfi",
  twitter: "https://twitter.com/aerariumfi",
  dex: "https://hermes.maiadao.io/#/swap",
  dapp: "https://dapp.aerariumfi.com",
  docs: "https://docs.aerariumfi.com",
};

const Header = ({ bgColor }: HeaderProps) => {
  const src = useColorModeValue(
    logoCoinBlackTransparent,
    logoCoinWhiteTransparent
  );
  const navigate = useNavigate();
  const [isSmallerThan1550] = useMediaQuery("(max-width: 1550px)");
  const [isSmallerThan900] = useMediaQuery("(max-width: 900px)");
  return (
    <Flex
      w="100%"
      flexFlow="row"
      justifyContent="space-between"
      alignItems="center"
      gridArea="header"
    >
      {isSmallerThan900 ? (
        <>
          {/* MOBILE HEADER */}
          <HStack justifySelf="flex-start">
            <Image
              src={src}
              objectFit={"contain"}
              boxSize="75px"
              onClick={() => navigate("/")}
              cursor="pointer"
              style={{marginTop:"40px", marginLeft:"40px"}}
            />
          </HStack>
          
          <Menu>
            <MenuButton
            style={{background:"#00BFFF"}}
              px={4}
              py={1}
              w="50%"
              as={Button}
              transition="all 0.2s"
              borderRadius="md"
              _focus={{ boxShadow: "outline" }}
            >
              <Flex
                flexFlow="row"
                alignItems="center"
                justifyContent="center"
                gap={2}
              >
                <HamburgerIcon />
                Menu
              </Flex>
            </MenuButton>

            <MenuList>
           
              <MenuItem icon={<FaHome />} onClick={() => navigate("/")}>
                Home
              </MenuItem>

              <MenuItem icon={<FaHome />} onClick={() => navigate("/")}>
                Presale
              </MenuItem>

              <MenuItem icon={<FaHome />} onClick={() => navigate("/")}>
                Create Node
              </MenuItem>
             
              <MenuDivider />
              <MenuItem
                icon={<FaTelegram fontSize={"1.5rem"} />}
              >
                Telegram
              </MenuItem>
              <MenuItem
                icon={<FaDiscord fontSize={"1.5rem"} />}
              >
                Discord
              </MenuItem>
              <MenuItem
                icon={<FaTwitter fontSize={"1.5rem"} />}
              >
                Twitter
              </MenuItem>
            </MenuList>
          </Menu>
        </>
      ) : (
        <>
          {/* LOGO */}
          {/* Left area */}
          <HStack justifySelf="flex-start">
            <Image
              src={src}
              objectFit={"contain"}
              boxSize="75px"
              onClick={() => navigate("/")}
              cursor="pointer"
              style={{marginTop:"40px", marginLeft:"40px"}}
            />
          </HStack>
          {/* Center area */}
          {isSmallerThan1550 ? (
            <>
              {/* MEDIUM SIZED HEADER */}
              <HStack
                justifySelf="flex-end"
                p={3}
                rounded="lg"
                w="40%"
                display="flex"
                justifyContent="center"
              >
                     <Button
                  rounded="lg"
                  w="30%"
                  onClick={() => navigate("/")}
                  style={{background:"none"}}
                >
                  Home
                </Button>
                <Button
                  rounded="lg"
                  w="30%"
                  onClick={() => navigate("/")}
                  style={{background:"none"}}
                >
                  Presale
                </Button>
                <Button
                  rounded="lg"
                  w="30%"
                  style={{background:"none"}}
                >
                  Create Node
                </Button>
                <Menu>
                  <MenuButton
                  style={{background:"none"}}
                    px={4}
                    py={1}
                    w="30%"
                    as={Button}
                    transition="all 0.2s"
                    borderRadius="md"
                    _focus={{ boxShadow: "outline" }}
                  >
                    <Flex
                      flexFlow="row"
                      alignItems="center"
                      justifyContent="center"
                      gap={2}
                    >
                      <HamburgerIcon />
                      More
                    </Flex>
                  </MenuButton>

                  <MenuList>
                    <MenuList>
                      
                      <MenuItem
                        icon={<GiCrownCoin fontSize="1.5rem" />}
                       
                      >
                        Buy $AXL
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem
                        icon={<FaTelegram fontSize={"1.5rem"} />}
                     
                      >
                        Telegram
                      </MenuItem>
                      <MenuItem
                        icon={<FaDiscord fontSize={"1.5rem"} />}
                       
                      >
                        Discord
                      </MenuItem>
                      <MenuItem
                        icon={<FaTwitter fontSize={"1.5rem"} />}
                       
                      >
                        Twitter
                      </MenuItem>
                    </MenuList>
                  </MenuList>
                </Menu>
              </HStack>
            </>
          ) : (
            <>
              {/* FULL SIZED HEADER */}
              <HStack
                justifySelf="flex-end"
                p={3}
                rounded="lg"
                w="40%"
                display="flex"
                justifyContent="center"
              >
                     <Button
                  rounded="lg"
                  w="30%"
                  onClick={() => navigate("/")}
                  style={{background:"none"}}
                >
                  Home
                </Button>
                <Button
                  rounded="lg"
                  w="30%"
                  onClick={() => navigate("/")}
                  style={{background:"none"}}
                >
                  Presale
                </Button>
                <Button
                  rounded="lg"
                  w="30%"
                  style={{background:"none"}}
                
                >
                  Create Node
                </Button>
                <Menu>
                  <MenuButton
                  style={{background:"none"}}
                    px={4}
                    py={1}
                    w="30%"
                    as={Button}
                    transition="all 0.2s"
                    borderRadius="md"
                    _focus={{ boxShadow: "outline" }}
                  >
                    <Flex
                      flexFlow="row"
                      alignItems="center"
                      justifyContent="center"
                      gap={2}
                    >
                      <HamburgerIcon />
                      More
                    </Flex>
                  </MenuButton>

                  <MenuList>
                    <MenuList>
                      
                      <MenuItem
                        icon={<GiCrownCoin fontSize="1.5rem" />}
                       
                      >
                        Buy $AXL
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem
                        icon={<FaTelegram fontSize={"1.5rem"} />}
                     
                      >
                        Telegram
                      </MenuItem>
                      <MenuItem
                        icon={<FaDiscord fontSize={"1.5rem"} />}
                       
                      >
                        Discord
                      </MenuItem>
                      <MenuItem
                        icon={<FaTwitter fontSize={"1.5rem"} />}
                       
                      >
                        Twitter
                      </MenuItem>
                    </MenuList>
                  </MenuList>
                </Menu>
              </HStack>
            </>
          )}
        </>
      )}

      {/* Right area 
      <HStack justifySelf="flex-end">
        {isSmallerThan900 ? (
          <></>
        ) : (
          <Button
            leftIcon={<GiCrownCoin fontSize="1.5rem" />}
            variant="bnb"
          >
            Connect
          </Button>
        )}

        <ColorModeSwitcher />
      </HStack>*/}
    </Flex>
  );
};

export default Header;
