import { extendTheme, ThemeComponentProps } from "@chakra-ui/react";
import { styles } from "./styles";

const themeC = extendTheme({
  fonts: {
    body: "Hurme Geometric Sans 3, sans-serif",
    heading: "'Hurme Geometric Sans 3', sans-serif",
  },
  components: {
    Button: {
      variants: {
        solid: (props: ThemeComponentProps) => ({
          bg: styles.colors.darkRed,
          color: styles.colors.white,
          _hover: {
            bg: styles.colors.darkRed,
            opacity: 0.75,
          },
          _focus: {
            bg: styles.colors.darkRed,
            opacity: 0.75,
          },
          _active: {
            bg: styles.colors.darkRed,
            opacity: 0.75,
          },
        }),
        solid2: (props: ThemeComponentProps) => ({
          bg:
            props.colorMode === "dark"
              ? styles.colors.yellow
              : styles.colors.yellow,
          color:
            props.colorMode === "dark"
              ? styles.colors.black
              : styles.colors.black,
          _hover: {
            bg:
              props.colorMode === "dark"
                ? styles.colors.yellow
                : styles.colors.yellow,
            opacity: 0.75,
          },
          _focus: {
            bg:
              props.colorMode === "dark"
                ? styles.colors.yellow
                : styles.colors.yellow,
            opacity: 0.75,
          },
          _active: {
            bg:
              props.colorMode === "dark"
                ? styles.colors.yellow
                : styles.colors.yellow,
            opacity: 0.75,
          },
        }),
        solid3: () => ({
          bg: styles.colors.darkRed,
          color: styles.colors.yellow,
          _hover: {
            bg: styles.colors.darkRed,
            opacity: 0.75,
          },
          _focus: {
            bg: styles.colors.darkRed,
            opacity: 0.75,
          },
          _active: {
            bg: styles.colors.darkRed,
            opacity: 0.75,
          },
        }),
        ftm: () => ({
          bg: styles.colors.darkBlue,
          _hover: {
            bg: styles.colors.darkBlue,
            opacity: 0.75,
          },
          _focus: {
            bg: styles.colors.darkBlue,
            opacity: 0.75,
          },
          _active: {
            bg: styles.colors.darkBlue,
            opacity: 0.75,
          },
        }),
        bnb: (props: ThemeComponentProps) => ({
          bg: styles.colors.yellow,
          color: styles.colors.black,
          _hover: {
            bg: styles.colors.yellow,
            opacity: 0.75,
          },
          _focus: {
            bg: styles.colors.yellow,
            opacity: 0.75,
          },
          _active: {
            bg: styles.colors.yellow,
            opacity: 0.75,
          },
        }),
        eth: () => ({
          bg: styles.colors.ethereum,
          color: styles.colors.white,
          _hover: {
            bg: styles.colors.ethereum,
            opacity: 0.75,
          },
          _focus: {
            bg: styles.colors.ethereum,
            opacity: 0.75,
          },
          _active: {
            bg: styles.colors.ethereum,
            opacity: 0.75,
          },
        }),
        avax: () => ({
          bg: styles.colors.red,
          color: styles.colors.black,
          _hover: {
            bg: styles.colors.red,
            opacity: 0.75,
          },
          _focus: {
            bg: styles.colors.red,
            opacity: 0.75,
          },
          _active: {
            bg: styles.colors.red,
            opacity: 0.75,
          },
        }),
      },
    },
    Heading: {
      baseStyle: (props: ThemeComponentProps) => ({
        color:
          props.colorMode === "dark" ? styles.colors.red : styles.colors.black,
      }),
    },
    Text: {
      baseStyle: (props: ThemeComponentProps) => ({
        color:
          props.colorMode === "dark"
            ? styles.colors.white
            : styles.colors.black,
      }),
    },
  },
  initialColorMode: "dark",
});

export default themeC;
