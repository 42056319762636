import { Flex, useColorMode, useMediaQuery } from "@chakra-ui/react";
import CardInformation from "./CardInformation";

import CardIntroduction from "./CardIntroduction";
import CardStrategies from "./CardStrategies";
import CardsPoints from "./CardsPoints";

const Content = () => {
  const { colorMode } = useColorMode();
  const [isSmallerThan1550] = useMediaQuery("(max-width: 1550px)");
  const [isSmallerThan1000] = useMediaQuery("(max-width: 1000px)");
  return (
    <Flex
      flexFlow="column"
      gap={12}
      gridArea="body"
      justifyContent="center"
      py={12}
      px={4}
    >
      <CardIntroduction
        colorMode={colorMode}
        isSmallerThan1000={isSmallerThan1000}
        isSmallerThan1550={isSmallerThan1550}
      />

      <CardStrategies
        colorMode={colorMode}
        isSmallerThan1000={isSmallerThan1000}
        isSmallerThan1550={isSmallerThan1550}
      />
      <CardsPoints
        colorMode={colorMode}
        isSmallerThan1000={isSmallerThan1000}
        isSmallerThan1550={isSmallerThan1550}
      />
    </Flex>
  );
};

export default Content;
