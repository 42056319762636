import {
  Flex,
  VStack,
  Heading,
  Grid,
  Text,
  Image,
  useDisclosure,
  ScaleFade,
  useMediaQuery,
  GridItem
} from "@chakra-ui/react";
import { useLayoutEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import {
  logoCoinBlackTransparent,
  iconShield,
  iconsBalance,
  logoCoinWhiteTransparent,
} from "../../styles/MediaPack";
import { styles } from "../../styles/styles";
import { CardIntroductionProps } from "./CardInformation";

interface CardProps {
  img: string;
  bgColor: string;
  heading: string | undefined;
  children: React.ReactNode;
  delay: number;
  style: any,
  color: string
}

/*const CardItem = ({ img, bgColor, heading, children, delay,style,color }: CardProps) => {
  const { isOpen, onToggle } = useDisclosure();
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  useLayoutEffect(() => {
    if (!isOpen && inView) {
      onToggle();
    }
  }, [inView, isOpen, onToggle]);
  return (
    <ScaleFade initialScale={0.75} in={isOpen} delay={delay}>
      <Flex
        h={"100%"}
        ref={ref}
        py={4}
        px={8}
        bg={bgColor}
        rounded="3xl"
        justifyItems="center"
        direction="column"
        gap={8}
        
      >
        <Image src={img} objectFit="contain" h="100%" maxH="200" />
        <VStack h="100%" alignItems="center">
          {heading && <Heading fontSize="1.75rem">{heading}</Heading>}
          {children}
        </VStack>
      </Flex>
    </ScaleFade>
  );
};*/

const CardsPoints = ({ colorMode }: CardIntroductionProps) => {
  const [isSmallerThan1200] = useMediaQuery("(max-width: 1200px)");

  const grid = useMemo(() => {
    if (isSmallerThan1200) return "100%";
    return "repeat(3, 30%)";
  }, [isSmallerThan1200]);

  const gridHeader = useMemo(() => {
    if (isSmallerThan1200) return 1;
    return 3;
  }, [isSmallerThan1200]);
  return (
    <>
    <Grid templateColumns={grid} gap={12} justifyContent="space-between" >
    <GridItem
    colSpan={gridHeader} 
    color="#fff"
    style={{marginBottom:"0px"}} 
      >
         <Heading style={{color:"#FFF",textShadow:"1px 1px 8px rgba(65,136,255)"}}>Uncovering Axxeleum NaaS</Heading>

      </GridItem>
      <GridItem
        bgColor={
          colorMode === "dark"
            ? styles.bg.lightTransparent
            : styles.bg.darkTransparent
        }
        color="#fff"
        style={{boxShadow:"0px 0px 5px 3px rgba(65,136,255,0.75)",WebkitBoxShadow:"0px 0px 5px 3px rgba(65,136,255,0.75)",MozBoxShadow: "0px 0px 5px 3px rgba(65,136,255,0.75)", marginTop:"0px",padding:"14px", color:"#fff"}}
        rounded="3xl"
      >
         <Heading style={{color:"#00BFFF",fontSize:"22px", paddingTop:"20px"}}>DeFi Yields Are Extremely High</Heading>

        <Text color="#fff" textAlign="left" lineHeight="28px" fontSize="16px" padding="20px">
        Axxeleum Finance is built to survive and outlast everyone else. It is
important for the team and system to keep track of both bullish and
bearish market characteristics so that they may profit from any market
conditions. As a result of the growth of successful protocols, users will
benefit by enjoying the New Decentralized Financial Infrastructure
benefits. Additionally, there is no downtime for Axxeleum Node
rewards. Investors receive their rewards straight from the protocol.
The cycle is repeated over and over again, providing investors with an
unlimited stream of passive income.
        </Text>
      </GridItem>
      <GridItem
        bgColor={
          colorMode === "dark"
            ? styles.bg.lightTransparent
            : styles.bg.darkTransparent
        }
        color=""
        style={{boxShadow:"0px 0px 5px 3px rgba(65,136,255,0.75)",WebkitBoxShadow:"0px 0px 5px 3px rgba(65,136,255,0.75)",MozBoxShadow: "0px 0px 5px 3px rgba(65,136,255,0.75)", marginTop:"0px",padding:"14px", color:"#fff"}}
        rounded="3xl"
      >
        <Heading style={{color:"#00BFFF",fontSize:"22px", paddingTop:"20px"}}>User Friendly</Heading>

        <Text color="#fff" textAlign="left" lineHeight="28px" fontSize="16px" padding="20px">
        Nobody enjoys stumbling around on difficult websites. After all,
performing this across several different protocols is time-consuming
and inefficient. Our platform enables anybody to quickly and easily
access and earn cutting-edge daily rewards and annual percentage
yields (APY) with little to no effort or knowledge of the DeFi business.
Simply set up your node, sit back, relax, and receive the benefits of life.
With Axxeleum Finance, your duty is straightforward: simply link your
wallet to our interface, purchase Axxeleum Node, and relax. All you
have to do is claim your daily rewards when the time comes.
        </Text>
      </GridItem>
      <GridItem
        bgColor={
          colorMode === "dark"
            ? styles.bg.lightTransparent
            : styles.bg.darkTransparent
        }
        color=""
        style={{boxShadow:"0px 0px 5px 3px rgba(65,136,255,0.75)",WebkitBoxShadow:"0px 0px 5px 3px rgba(65,136,255,0.75)",MozBoxShadow: "0px 0px 5px 3px rgba(65,136,255,0.75)", marginTop:"0px", padding:"14px", color:"#fff"}}
        rounded="3xl"
      >
         <Heading style={{color:"#00BFFF",fontSize:"22px", paddingTop:"20px"}}>Exhaustive Verification Protocols</Heading>
         
        <Text color="#fff" textAlign="left" lineHeight="28px" fontSize="16px" padding="20px">
        We thoroughly examine all processes before making any investments
to limit risk. What is even more intriguing is that we enable our
community to choose from a list of protocols that have been
thoroughly selected and validated.
          stability.
        </Text>
      </GridItem>
    </Grid>
    </>
  );
};

export default CardsPoints;
